import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactFormService } from '../../../services/contact-form.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: UntypedFormGroup;
  public error: any;
  public success: boolean;
  public loading: boolean;

  constructor(
    private contactFormService: ContactFormService
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.contactForm = new UntypedFormGroup({
      full_name: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      gas_safe_no: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [Validators.required])
    });
  }

  submitContactForm() {
    this.loading = true;
    this.error = null;
    this.contactFormService.sendRequest({
      full_name: this.get('full_name').value,
      email: this.get('email').value,
      gas_safe_number: this.get('gas_safe_no').value,
      message: this.get('message').value
    }).subscribe(
      data => this.success = true,
      err => {
        console.log(err);
        this.error = err;
        this.loading = false;
      },
      () => this.loading = false
    );
  }

  public showFormError(key: string): boolean {
    const control: AbstractControl = this.get(key);
    return control.invalid && (control.touched || control.dirty);
  }

  public get(key: string): AbstractControl {
    if (!key) {
      throw new Error('A key must be provided');
    }

    if (!this.contactForm.contains(key)) {
      throw new Error('Invalid control name provided');
    }

    return this.contactForm.get(key);
  }

}
