import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import Installer from '../../../models/installer.model';
import { InstallerConnectService } from '../../../services/installer-connect.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output()
  loginSuccess: EventEmitter<Installer> = new EventEmitter<Installer>();

  @Output()
  loginFailure: EventEmitter<Installer> = new EventEmitter<Installer>();

  @Output()
  loginCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loginSubmitted: EventEmitter<any> = new EventEmitter<any>();

  public loginForm: UntypedFormGroup;
  public loading: boolean;
  public error: any;

  constructor(
    private installerConnectService: InstallerConnectService,
    private router: Router,
    private titleService: Title,
    private renderer: Renderer2
  ) {
    this.loginForm = this.createFormGroup();
    this.loading = false;
    this.error = false;
  }

  ngOnInit() {
    this.titleService.setTitle('Login | Premier Club 2023');
    this.renderer.addClass(document.body, 'login-bod');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-bod');
  }

  /**
   * Handle a form submission - Emits events on success/error with data
   */
  public onSubmit(): void {
    this.error = false;
    this.loading = true;
    this.loginSubmitted.emit();
    this.loginForm.markAllAsTouched();

    if (!this.get('email').value.length || !this.get('password').value.length) {
      this.loading = false;
      this.loginCancelled.emit();
      return;
    }

    this.installerConnectService.getProfile(this.get('email').value, this.get('password').value).subscribe(
      data => {
        if (!data.extraEnabled) {
          // This installer is not part of the scheme, do not allow them to login
          this.error = true;
        } else {
          this.installerConnectService.storeProfileData(data);
          this.router.navigate(['my-performance']);
        }
      },
      () => {
        this.loading = false;
        this.error = true;
      },
      () => (this.loading = false)
    );
  }

  private createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required]),
    });
  }

  /**
   * Convenience method to determine whether a control is both invalid,
   * and has been interacted with by the user.
   * @param key - Identifier for the control as defined in this.loginForm
   */
  public showFormError(key: string): boolean {
    const control: AbstractControl = this.get(key);
    return control.invalid && (control.touched || control.dirty);
  }

  /**
   * Retrieve a form control from the loginForm
   * @param key - Identifier for the control as defined in this.loginForm
   */
  public get(key: string): AbstractControl {
    if (!key) {
      throw new Error('A key must be provided');
    }

    if (!this.loginForm.contains(key)) {
      throw new Error('Invalid control name provided');
    }

    return this.loginForm.get(key);
  }
}
