<div class="login-container">
  <div class="login-content">
    <a href="/">
      <img src="/assets/images/ideal-heating-black.png" class="login-logo">
    </a>
    <h1>Login</h1>
    <div class="form__alert" *ngIf="error">
      <strong>Sorry, there was a problem</strong><br >Please double check your email and password and try again
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="form__group email-input" [ngClass]="{'form__group--error' : showFormError('email')}">
        <label class="form__label" for="email">Email Address</label>
        <input type="email" class="form__input form__input--email" name="email" id="email" required
          placeholder="Email Address" formControlName="email" />
        <span class="form__error" *ngIf="showFormError('email')">
          A valid email address must be provided
        </span>
      </div>
      <div class="form__group password-input" [ngClass]="{'form__group--error' : showFormError('password')}">
        <label class="form__label" for="password">Password</label>
        <input type="password" class="form__input form__input--password" name="password" id="password" required
          placeholder="Password" formControlName="password" />
        <span class="form__error" *ngIf="showFormError('password')">
          A password must be provided
        </span>
      </div>
      <div class="form__group">
        <button type="submit" class="login-btn" [disabled]="loading">
          <span *ngIf="!loading">Login</span>
          <span *ngIf="loading">Logging in...</span>
        </button>
      </div>
    </form>
  </div>
</div>
