export const environment = {
  production: false,
  logger: {
    url: 'http://localhost',
  },
  api: {
    url: 'https://api.staging.envs.installerconnectinfra.co.uk/premier',
  },
  contactForm: {
    url: 'https://localhost',
  },
  analytics: {
    trackingId: null,
  },
};
