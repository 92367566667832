import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent implements OnInit {
  public titleMap: any = {
    '/': 'Ideal Heating | Premier Club 2023',
  };

  public assetMap: any = {};

  public pageContent: SafeHtml;
  public loading: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private titleService: Title,
    private renderer: Renderer2,
    private sanitiser: DomSanitizer
  ) {
    this.loading = true;
  }

  ngOnInit() {
    let url;
    if (this.router.url === '/') {
      url = '/assets/html/index.html';
    } else {
      url = `/assets/html/${this.router.url.replace('/', '')}.html`;
    }

    this.http
      .get(url, {
        responseType: 'text',
      })
      .subscribe(
        data => {
          this.pageContent = this.sanitiser.bypassSecurityTrustHtml(data);
          this.titleService.setTitle(this.titleMap[this.router.url] || 'Premier Club 2023');
        },
        err => this.router.navigate(['/not-found'], { skipLocationChange: true }),
        () => (this.loading = false)
      );

    const staticRoutes: string[] = ['/contact', '/destination', '/hotel', '/itinerary', '/oldtrip', '/'];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && staticRoutes.indexOf(event.urlAfterRedirects) !== -1) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate([event.urlAfterRedirects]).then();
      }
    });
  }
}
