import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../environments/environment';

export interface ContactFormRequest {
  full_name: string;
  email: string;
  gas_safe_number: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContactFormService {
  constructor(private http: HttpClient) {}

  sendRequest(data: ContactFormRequest): Observable<any> {
    return this.http.post(env.contactForm.url, data);
  }
}
