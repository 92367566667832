import { Component, OnInit } from '@angular/core';
import { InstallerConnectService } from '../../../services/installer-connect.service';
import { Title } from '@angular/platform-browser';
import Installer from '../../../models/installer.model';

@Component({
  selector: 'app-points',
  templateUrl: './my-performance.component.html',
  styleUrls: ['./my-performance.component.scss'],
})
export class MyPerformanceComponent implements OnInit {
  public profileData: Installer;
  public loading: boolean;
  public error: boolean;
  public extraTargets: Array<any> = [
    {
      title: 'Q1 £250 Travelex Card',
      issued: {
        title: 'Issued from',
        date: 'April 2023',
      },
    },
    {
      title: 'Q2 Pair of Ray Bans',
      issued: {
        title: 'Issued from',
        date: 'July 2023',
      },
    },
    {
      title: 'Q3 Exclusive trip experience in Dubai',
      issued: {
        title: 'Confirmation from',
        date: 'October 2023',
      },
    },
    {
      title: 'Q4 Hugo Boss backpack with luxury amenities',
      issued: {
        title: 'Issued from',
        date: 'January 2024',
      },
    },
    {
      title: 'Q5 £250 Travelex Card',
      issued: {
        title: 'Issued from',
        date: 'April 2024',
      },
    },
  ];
  public pointsPassport = 0;

  constructor(private installerConnectService: InstallerConnectService, private titleService: Title) {
    this.loading = true;
    this.error = false;
  }

  ngOnInit() {
    this.titleService.setTitle('My Performance | Premier Club 2023');
    this.installerConnectService.getProfileData().then((data: any) => {
      this.loading = false;
      this.profileData = data;

      if (this.profileData.extraTargets) {
        this.pointsPassport =
          this.profileData.extraTargets[this.profileData.extraTargets.length - 1].cumulativePointsTarget;
      } else {
        this.pointsPassport = this.profileData.pointsTarget;
      }

      this.calculateAnimations();

      this.profileData.enrolledAt = new Date(this.profileData.enrolledAt.toString().replace(/ /g, 'T'));
      this.profileData.updatedAt = new Date(this.profileData.updatedAt.toString().replace(/ /g, 'T'));
    });
  }

  private calculateAnimations() {
    const points = this.profileData.points;
    const pointsDistance = points / this.pointsPassport;
    const maxValue = 1000;
    const value = maxValue * pointsDistance;

    let maskX: number;
    let planeX: number;

    if (value > 883) {
      planeX = 883;
    } else {
      planeX = value;
    }

    if (value > 973) {
      maskX = 27;
    } else {
      maskX = maxValue - value;
    }

    // @ts-ignore
    TweenMax.to('#mask', 4, { attr: { x: -maskX }, ease: 'Power1.easeInOut' }); // max value -27 (ie. 973)
    // @ts-ignore
    TweenMax.to('#plane', 3.8, { x: planeX, ease: 'Power1.easeInOut' }); // max value 883
  }

  public extraTargetLeftValue(target) {
    return (target / this.pointsPassport) * 100 + '%';
  }
}
