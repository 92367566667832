import { Component, OnInit } from '@angular/core';
import { InstallerConnectService } from '../../../services/installer-connect.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  public displayLoginButton = false;
  constructor(private installerConnectService: InstallerConnectService) {}

  async ngOnInit() {
    const profileData = await this.installerConnectService.getProfileData();
    if (!profileData) {
      this.displayLoginButton = true;
    }
  }
}
