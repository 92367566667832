<div class="wrapper">
  <div class="container">
    <div class="page-heading">
      <h1>Contact Us</h1>
    </div>
  </div>

  <video autoplay playsinline loop muted class="wrapper__video">
    <source src="/assets/videos/mp4/contact.mp4">
    <source src="/assets/videos/webm/contact.webm">
  </video>
</div>
<main>
  <div class="container">
    <div class="flex">
      <div class="contact-content">
        <h3>At Ideal Heating, we are committed to delivering the highest level of customer service. </h3>
        <br> <p>With more than 100 years' experience in the heating industry, we know how important confidence and trust are to our customer. We're more than just your average boiler company.
        <br><br>
        We strive to keep our installers at the heart of everything we do. From in-depth product information and on-site training to Installer Connect rewards and business support, we can ensure you'll get more
        from Ideal.
        <br><br>
        As part of our exciting rewards we offer our most loyal installers to join us on amazing experiences like Premier Club.
        <br><br>
        Email: <a href="mailto:ideal@idealboilers.com">ideal@idealboilers.com</a>
        <br><br>
        If you experience any technical difficulties accessing My Performance page, please email <a href="milto:premierclub2021@firstevent.co.uk">premierclub2021@firstevent.co.uk</a>.</p>
      </div>

      <div class="contact-form">
        <h3>Contact Us</h3>
        <br>
        <form [formGroup]="contactForm" (ngSubmit)="submitContactForm()" autocomplete="off" *ngIf="!success">
          <div class="name-input">
            <input type="text" placeholder="Full Name"
                   formControlName="full_name" [ngClass]="{'invalid': showFormError('full_name')}"
            >
          </div>

          <div class="password-input">
            <input type="email" placeholder="Email"
                   formControlName="email" [ngClass]="{'invalid': showFormError('email')}"
            >
          </div>

          <div class="gsn-input">
            <input type="text" placeholder="Gas Safe Number"
                   formControlName="gas_safe_no" [ngClass]="{'invalid': showFormError('gas_safe_no')}">
          </div>

          <div class="message-input">
            <textarea id="subject" placeholder="Your message" style="height:200px"
                      formControlName="message" [ngClass]="{'invalid': showFormError('message')}"
            ></textarea>
          </div>

          <button type="submit" class="submit-btn" [disabled]="contactForm.invalid">Enter</button>
        </form>
        <div class="form-alert" *ngIf="success || error != null">
          <div class="form-alert-content error" *ngIf="error != null">
            <p>Sorry, we weren't able to process your request, please try again later.</p>
          </div>
          <div class="form-alert-content success" *ngIf="success">
            <p>Thank you, your request has been sent successfully.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>


