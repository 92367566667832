<div class="perf-container">

  <div class="title-logout">
    <h1>My Performance</h1>
    <div class="logout-btn"><a [routerLink]="['/logout']">logout</a></div>
  </div>

  <p class="last-updated">This page was last updated at <span *ngIf="profileData && !loading else loadPlaceholder">{{ profileData.last_updated | date:'HH:mm \on\ dd/MM/yyyy' }}</span>, <a [routerLink]="['/logout']">logout</a> to refresh.</p>

  <div class="flex">
    <div class="welcome section">
      <h2 *ngIf="!profileData || loading">Welcome back</h2>
      <h2 *ngIf="profileData && !loading">Welcome, {{ profileData.forename }}</h2>
      <hr>
      <h3>Your Details</h3>
      <div class="flex">
        <div class="info" style="flex:1;">
          <h4>Full Name</h4>
          <p *ngIf="profileData && !loading else loadPlaceholder">
            {{ profileData.forename }} {{ profileData.surname }}
          </p>
        </div>

        <div class="info" style="flex:2;">
          <h4>Email Address</h4>
          <p *ngIf="profileData && !loading else loadPlaceholder">
            {{ profileData.email }}
          </p>
        </div>
      </div>
    </div>

    <div class="enrollment section">
      <div class="flex">
        <h2>Enrollment</h2>
      </div>
      <hr>
      <h3>Your enrollment details</h3>
      <div class="flex">
        <div class="info" style="flex:1;">
          <h4>Enrollment date</h4>
          <p *ngIf="profileData && !loading else loadPlaceholder">
            {{ profileData.enrolledAt | date:'dd/MM/yyyy' }}
          </p>
        </div>

        <div class="info" style="flex:1;">
          <h4>Updated at</h4>
          <p *ngIf="profileData && !loading else loadPlaceholder">
            {{ profileData.updatedAt | date:'HH:mm dd/MM/yyyy' }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="points">
    <div class="points-header">
      <h2>Your Points</h2>
    </div>

    <div class="extra-targets" *ngIf="profileData && !loading && profileData.extraTargets">
      <div class="extra-targets__inner">
        <div *ngFor="let target of profileData.extraTargets; let i = index">
          <div class="extra-targets__target" [ngClass]="{'extra-targets__target--hit' : profileData.points >= target.cumulativePointsTarget}" [ngStyle]="{'left': extraTargetLeftValue(target.cumulativePointsTarget)}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23">
              <path d="M13.28,0.8l2.93,5.95l6.55,0.96c0.79,0.12,1.33,0.85,1.21,1.63c-0.05,0.31-0.19,0.6-0.42,0.82 l-4.75,4.59l1.12,6.54c0.13,0.78-0.39,1.52-1.17,1.66c-0.31,0.05-0.63,0-0.91-0.14L12,19.75l-5.86,3.09 c-0.7,0.37-1.57,0.1-1.94-0.61c-0.15-0.28-0.2-0.6-0.14-0.91l1.12-6.54l-4.74-4.63C-0.13,9.6-0.15,8.69,0.4,8.12 C0.63,7.89,0.92,7.74,1.23,7.7l6.55-0.96l2.93-5.95c0.35-0.71,1.21-1,1.92-0.65C12.92,0.29,13.15,0.52,13.28,0.8z"/>
            </svg>
            <div class="extra-targets__target__modal">
              <div class="extra-targets__target__modal__inner">
                <div class="extra-targets__target__modal__title">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                    <path d="M10.69,0h0.15c2.25,0,4.32,1.19,5.46,3.12L18,6.01l1.69-2.88C20.83,1.19,22.91,0,25.16,0h0.16 c3.42,0,6.19,2.77,6.19,6.19C31.49,7.16,31.26,8.13,30.83,9h2.92C34.99,9,36,10,36,11.25c0,0,0,0,0,0v4.5c0,1.24-1,2.25-2.25,2.25 c0,0,0,0,0,0H2.25C1.01,18,0,16.99,0,15.75c0,0,0,0,0,0v-4.5C0,10.01,1.01,9,2.25,9c0,0,0,0,0,0h2.92C4.74,8.13,4.51,7.16,4.5,6.19 C4.5,2.77,7.27,0,10.69,0z M13.39,4.84c-0.53-0.9-1.5-1.46-2.55-1.46h-0.15c-1.55,0-2.81,1.26-2.81,2.81c0,0,0,0,0,0 C7.88,7.74,9.13,9,10.69,9c0,0,0,0,0,0h5.15L13.39,4.84z M25.31,3.38h-0.16c-1.05,0-2.02,0.56-2.55,1.46L20.16,9h5.15 c1.55,0,2.81-1.26,2.81-2.81c0,0,0,0,0,0C28.13,4.63,26.87,3.38,25.31,3.38C25.31,3.38,25.31,3.38,25.31,3.38z M2.25,20.25h13.5V36 H5.62c-1.86,0-3.37-1.51-3.37-3.38V20.25z M20.25,36V20.25h13.5v12.38c0,1.86-1.51,3.37-3.38,3.38H20.25z"/>
                  </svg>
                  <p>{{extraTargets[i].title}}</p>
                </div>
                <div class="extra-targets__target__modal__issued">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                    <path d="M36,7.87C36,7.87,36,7.87,36,7.87v3.38H0V7.88C0.01,6,1.55,4.49,3.42,4.5c0,0,0,0,0,0h3.43V2.25 C6.87,1,7.89-0.01,9.14,0c0,0,0,0,0,0c1.25-0.01,2.28,1,2.29,2.25c0,0,0,0,0,0V4.5h13.14V2.25C24.58,1,25.6-0.01,26.86,0 c0,0,0,0,0,0c1.25-0.01,2.28,1,2.29,2.25c0,0,0,0,0,0V4.5h3.43C34.45,4.49,35.99,6,36,7.87z M36,13.5v19.12 c-0.01,1.88-1.55,3.39-3.42,3.38c0,0,0,0,0,0h-4c0,0,0,0,0,0c0,0,0,0,0,0H7.43h-4C1.55,36.02,0.02,34.51,0,32.63c0,0,0,0,0,0V13.5h4 h28H36z M8.57,28.13C8.57,27.5,8.05,27,7.43,27H5.14C4.52,27,4.01,27.5,4,28.13v2.25C4.01,31,4.52,31.5,5.14,31.5h2.29 c0.63,0,1.14-0.5,1.14-1.12V28.13z M8.57,19.12C8.57,18.5,8.05,18,7.43,18H5.14C4.52,18,4.01,18.5,4,19.12v2.25 C4.01,22,4.52,22.5,5.14,22.5h2.29c0.63,0,1.14-0.5,1.14-1.12V19.12z M16.38,28.13C16.37,27.5,15.86,27,15.24,27h-2.29 c-0.63,0-1.14,0.5-1.14,1.12v2.25c0.01,0.63,0.52,1.13,1.14,1.12h2.29c0.63,0,1.14-0.5,1.14-1.12V28.13z M16.38,19.12 C16.37,18.5,15.86,18,15.24,18h-2.29c-0.63,0-1.14,0.5-1.14,1.12v2.25c0.01,0.63,0.52,1.13,1.14,1.12h2.29 c0.63,0,1.14-0.5,1.14-1.12V19.12z M24.19,28.13C24.18,27.5,23.67,27,23.05,27h-2.29c-0.63,0-1.14,0.5-1.14,1.12v2.25 c0.01,0.63,0.52,1.13,1.14,1.12h2.29c0.63,0,1.14-0.5,1.14-1.12V28.13z M24.19,19.12C24.18,18.5,23.67,18,23.05,18h-2.29 c-0.63,0-1.14,0.5-1.14,1.12v2.25c0.01,0.63,0.52,1.13,1.14,1.12h2.29c0.63,0,1.14-0.5,1.14-1.12V19.12z M32,28.13 C31.99,27.5,31.48,27,30.86,27h-2.29c-0.63,0-1.14,0.5-1.14,1.12v2.25c0.01,0.63,0.52,1.13,1.14,1.12h2.29 c0.63,0,1.14-0.5,1.14-1.12V28.13z M32,19.12C31.99,18.5,31.48,18,30.86,18h-2.29c-0.63,0-1.14,0.5-1.14,1.12v2.25 c0.01,0.63,0.52,1.13,1.14,1.12h2.29c0.63,0,1.14-0.5,1.14-1.12V19.12z"/>
                  </svg>
                  <p>{{extraTargets[i].issued.title}} <br /><strong>{{extraTargets[i].issued.date}}</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mp-bar">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" width="1000" height="100">
          <line id="runway" style="stroke:#C5C5C5;stroke-width:14;stroke-miterlimit:10;stroke-dasharray:32,28;" x1="-20" y1="50" x2="973" y2="50" fill="none" />
          <rect id="mask" width="1000" height="14" x="-1000" y="43" fill="#3C2A66" />
          <path id="plane" fill="#db3c3f" d="M31.2,100h8l5.5-10.7h4.5c2,0,3.5-1.5,3.2-3.3l0,0c0-2-1.5-3.5-3.2-3.3h-1l4.2-8.5h4.1 c2,0,3.5-1.5,3.2-3.3l0,0c0-2-1.5-3.5-3.2-3.3H56l5.8-11.5l7.7-0.3c27.2,1,27.5-13,0-12.3L61.8,44L56,32.5h0.5 c2,0,3.5-1.5,3.2-3.3l0,0c0-2-1.5-3.5-3.2-3.3h-4.1l-4.2-8.5h1c2,0,3.5-1.5,3.2-3.3l0,0c0-2-1.5-3.5-3.2-3.3h-4.5L39.3,0h-8 l11.3,39.1c0.7,2.3-1,4.5-3.2,4.8l-26.2,2L6,32.2h-3l2.3,12C5.6,46,4.6,48,2.8,48.3C1,48.5,0,49,0,50.1c0,1,1.5,1.5,2.8,1.8 c1.8,0.3,3,2,2.5,4.1L3.1,68.1h3l7-13.7l26.2,2c2.3,0.3,4.1,2.5,3.2,4.8L31.2,100z"/>
          <path id="pin" transform="translate(973,0)" fill="#3C2A66" d="M13,0C6,0,0.4,5.7,0.4,12.6C0.4,25.1,13,40,13,40s12.6-14.9,12.6-27.4C25.8,5.7,20.2,0,13,0z M13,19.9c-4.1,0-7.5-3.4-7.5-7.5S8.9,4.9,13,4.9s7.5,3.4,7.5,7.5S17.1,19.9,13,19.9z"/>
          <path id="pin-drop" transform="translate(973,0)" fill="#3C2A66" d="M7.6,50.6c0-3,2.5-5.4,5.4-5.4s5.4,2.5,5.4,5.4S15.9,56,13,56S7.6,53.6,7.6,50.6z"/>
      </svg>
    </div>

    <div class="flex">
      <div class="info">
        <h4>Total points</h4>
        <h3 *ngIf="profileData && !loading else loadPlaceholder">{{ profileData.points }}</h3>
      </div>

      <div class="info">
        <h4>Points passport</h4>
        <h3 *ngIf="profileData && !loading else loadPlaceholder">{{ pointsPassport }}</h3>
      </div>
    </div>

    <div class="flex">
      <div class="info points-calc">
        <h4>How your points are calculated</h4>
        <p>Earn 10 Points for Logic MAX, Logic +, Vogue Max & Vogue registrations, 5 Points for Ideal 5-Yr warranty boiler models and 1 Point for Controls.</p>
      </div>

      <div class="info points-req">
        <h4 *ngIf="profileData && !loading else loadPlaceholder">Points required: {{ pointsPassport - profileData.points}}</h4>
      </div>
    </div>
  </div>

  <div class="totals-holder">
    <div class="flex">
      <div class="totals">
        <h2>Premium Boilers</h2>
        <hr>
        <div class="info">
          <div class="flex">
            <h4>Total installed</h4>
            <h4 *ngIf="profileData && !loading else loadPlaceholder">{{ profileData.totalPremiumBoilerRegistrations }}</h4>
          </div>
        </div>
      </div>

      <div class="totals">
        <h2>Base Boilers</h2>
        <hr>
        <div class="info">
          <div class="flex">
            <h4>Total installed</h4>
            <h4 *ngIf="profileData && !loading else loadPlaceholder">{{ profileData.totalBaseBoilerRegistrations }}</h4>
          </div>
        </div>
      </div>

      <div class="totals">
        <h2>Controls</h2>
        <hr>
        <div class="info">
          <div class="flex">
            <h4>Total installed</h4>
            <h4 *ngIf="profileData && !loading else loadPlaceholder">{{ profileData.totalControlRegistrations }}</h4>
          </div>
        </div>
      </div>

      <div class="totals">
        <h2>Cylinders</h2>
        <hr>
        <div class="info">
          <div class="flex">
            <h4>Total installed</h4>
            <h4 *ngIf="profileData && !loading else loadPlaceholder">{{ profileData.totalCylinderRegistrations }}</h4>
          </div>
        </div>
      </div>

      <div id="form-wrapper" class="form-wrapper" *ngIf="profileData && !loading && profileData.points >= pointsPassport">
        <p>Congratulations on qualifying for the Ideal Premier Club Dubai 2024. We kindly ask you to fill out the details below:</p>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdKckxU2nwAz3O25v9Nr2xzuoMyyL0Jns18IccAQGL4AnhzqA/viewform?embedded=true" width="100%" height="1400px">Loading…</iframe>
      </div>
    </div>
  </div>

</div>

<ng-template #loadPlaceholder>
  <small>Loading...</small>
</ng-template>
