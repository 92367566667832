<div class="wrapper">
    <div class="container">
        <div class="home-heading">
            <!-- <h1>Las Vegas</h1>
            <h2>July 2021</h2> -->
        </div>
    </div>
    <img src="assets/images/IHD_2301206_Premier_Club_Extra_Dubai_Web_Header_1900x720.jpeg" alt="Dubai Premier Club Extra" style="margin-top:90px;width:100%;">
    <!-- <video autoplay playsinline loop muted class="wrapper__video">
      <source src="/assets/videos/mp4/home-dest.mp4">
      <source src="/assets/videos/webm/home-dest.webm">
    </video> -->
</div>

<main>
    <div class="container">
        <div class="flex">
            <div class="home-content">
                <h3>Boost your Premier Club rewards as we head toward our 2024 Ideal Heating adventure</h3>
                <br />
                <p>In 2019 we sent our installers on our first Premier Club trip to <strong>Miami</strong> in the sunshine state of Florida. In 2022 we visited the bright lights of the <strong>Las Vegas</strong> strip and in 2024 we head to the breath-taking destination of <strong>Dubai</strong>.</p>
                <div class="home-content-btn" *ngIf="displayLoginButton"><a href="/login">Login now to track your progress</a></div>
                <div class="home-content-btn" *ngIf="!displayLoginButton"><a href="/my-performance">My Performance</a></div>
            </div>
            <div class="home-cta-btns">
                <!-- <div class="home-cta">
                  <a href="/destination"><div class="flex">
                      <i class="fas fa-map-marker-alt fa-2x"></i>
                      <h3>Destination</h3>
                      <i class="fas fa-chevron-right fa-2x"></i>
                    </div></a>
                </div>
                <div class="home-cta">
                  <a href="/my-performance"><div class="flex">
                      <i class="fas fa-chart-line fa-2x"></i>
                      <h3>My Performance</h3>
                      <i class="fas fa-chevron-right fa-2x"></i>
                    </div></a>
                </div>
                <div class="home-cta">
                  <a href="/oldtrip"><div class="flex">
                      <i class="fas fa-glass-cheers fa-2x"></i>
                      <h3>2019 Trip</h3>
                      <i class="fas fa-chevron-right fa-2x"></i>
                    </div></a>
                </div>
                <p>Haven't registered yet?</p>
                <div class="home-cta">
                  <a href="/enrolment"><div class="flex">
                      <i class="fas fa-signature fa-2x"></i>
                      <h3>Register Now</h3>
                      <i class="fas fa-chevron-right fa-2x"></i>
                    </div></a>
                </div> -->
            </div>
        </div>
    </div>
</main>
