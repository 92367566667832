<header>
  <div class="container">
    <div class="flex">
      <a [routerLink]="['/']">
        <img src="/assets/images/ideal-heating.png" class="header-logo">
      </a>
      <nav class="nav">
        <a class="skip" [routerLink]="[]" (click)="toggleNav()">Menu</a>
        <ul id="menu">
          <li><a [routerLink]="['/']" (click)="toggleNav()">Home</a></li>
          <!-- <li><a [routerLink]="['/destination']" (click)="toggleNav()">Destination</a></li>
          <li><a [routerLink]="['/hotel']" (click)="toggleNav()">Hotel</a></li>
          <li><a [routerLink]="['/itinerary']" (click)="toggleNav()">Itinerary</a></li>
          <li><a [routerLink]="['/contact']" (click)="toggleNav()">Contact Us</a></li> -->
          <li><a [routerLink]="['/my-performance']" (click)="toggleNav()">My Performance</a></li>
          <!-- <li><a [routerLink]="['/oldtrip']" (click)="toggleNav()">2019 Trip</a></li> -->
        </ul>
      </nav>
    </div>
  </div>
</header>

<router-outlet></router-outlet>

<footer>
  <div class="container">
    <div class="social-med">
      <a href="https://twitter.com/idealheating" target="_blank"><img src="/assets/images/twitter-brands.svg"></a>
      <a href="https://www.facebook.com/idealboilers/" target="_blank"><img src="/assets/images/facebook-brands.svg"></a>
      <a href="https://www.instagram.com/ideal_boilers/" target="_blank"><img src="/assets/images/instagram-brands.svg"></a>
      <a href="https://www.youtube.com/user/IdealHeatingLtd" target="_blank"><img src="/assets/images/youtube-brands.svg"></a>
      <a href="https://www.linkedin.com/company/ideal-boilers/" target="_blank"><img src="/assets/images/linkedin.svg"></a>
    </div>
  </div>

  <div class="purple-band">
    <div class="container">
      <p>Designed by <a href="https://www.firstevent.co.uk/" target="_blank">First Event</a> | 2023</p>
    </div>
  </div>
</footer>
